import * as React from 'react';
import {
  GoogleLogin,
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from 'react-google-login';
import Image from 'next/image';

export function GoogleLoginButton(props?: {
  onGoogleSignIn: (
    response: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => void;
  onFail: (error: unknown) => void;
  googleLogo?: string;
  text: string;
}) {
  return (
    <GoogleLogin
      clientId="848393162485-vrkdrqbssecb5ht1o3lu88l6j4f6c70i.apps.googleusercontent.com"
      buttonText="Login"
      scope={
        'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events'
      }
      onSuccess={props.onGoogleSignIn}
      onFailure={props.onFail}
      // redirectUri="http://localhost:4200/authRedirect"
      render={(renderProps) => (
        <button
          onClick={renderProps.onClick}
          disabled={renderProps.disabled}
          className={`flex justify-center items-center h-12 w-full bg-white  rounded text-gray-900 font-semibold shadow-file-button `}
        >
          {
            <span style={{ marginRight: 8 }}>
              <div
                style={{
                  width: 25,
                  height: 25,
                  backgroundImage:
                    'url("https://fluentcdn.blob.core.windows.net/podcasts/4006fe30-8ced-11eb-8466-5dbd0bc494b2.png")',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              />
            </span>
          }
          {props.text}
        </button>
      )}
      isSignedIn={false}
      cookiePolicy={'single_host_origin'}
    />
  );
}
