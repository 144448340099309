import { gql, useMutation } from '@apollo/client';
import {
  ASYNC_STATUS,
  googleLogin,
  GoogleLoginPlatformType,
  UserInfo,
} from '@fluentsolar/types';
import * as React from 'react';

export const MUTATION_GOOGLE_LOGIN = gql`
  mutation googleLogin($googleToken: String!, $type: String) {
    googleLogin(googleToken: $googleToken, type: $type) {
      auth
      message
      user {
        id
        email
        name
        role
        team
        profilePic
        salesRepInfo {
          id
        }
        setterInfo {
          id
        }
        officeName
      }
    }
  }
`;

export function useGoogleLogin(props?: { type?: GoogleLoginPlatformType }) {
  const [googleLoginFn, googleLoginInfo] = useMutation<googleLogin>(
    MUTATION_GOOGLE_LOGIN
  );
  const [userInfo, setUserInfo] = React.useState<UserInfo>(null);

  const [message, setMessage] = React.useState('');
  const [status, setStatus] = React.useState<ASYNC_STATUS>(ASYNC_STATUS.IDLE);
  // const { setAllState } = useApolloState();
  // const router = useRouter();

  async function onGoogleSignIn(googleUser) {
    console.log('hey');
    setMessage('Logging You In..');
    setStatus(ASYNC_STATUS.PENDING);
    // router.push('/dashboard/profile');
    const auth = await googleUser.getAuthResponse();
    const profile = googleUser.getBasicProfile();
    const email = profile.getEmail();
    const name = profile.getName();
    const pic = profile.getImageUrl();
    setUserInfo({ email, name, pic });

    googleLoginFn({
      variables: { googleToken: auth.id_token, type: props?.type },
    });
  }

  async function gLogin(googleToken?: string, type?: GoogleLoginPlatformType) {
    setMessage('Logging You In..');
    setStatus(ASYNC_STATUS.PENDING);
    const result = await googleLoginFn({
      variables: { googleToken, type },
    });
    return result;
  }

  React.useEffect(() => {
    if (googleLoginInfo.data?.googleLogin?.message) {
      setMessage(googleLoginInfo.data.googleLogin.message);
    }

    if (googleLoginInfo?.data?.googleLogin?.auth) {
      setMessage(googleLoginInfo.data.googleLogin.message);
      setStatus(ASYNC_STATUS.SUCCESS);

      setUserInfo({
        ...userInfo,
        ...googleLoginInfo?.data?.googleLogin?.user,
      });
    }
  }, [googleLoginInfo?.data]);

  function onGoogleFail(e) {
    console.log('onGoogleFail', e);
    setMessage('Login failed.');
  }

  return {
    message,
    status,
    onGoogleSignIn,
    onGoogleFail,
    googleLoginInfo,
    userInfo,
    gLogin,
  };
}
