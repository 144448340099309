export enum ASYNC_STATUS {
  IDLE = 'IDLE',
  PENDING = 'PENDING',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}

export interface CommonAsyncProps {
  status?: ASYNC_STATUS;
  message?: string;
}
