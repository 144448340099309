import { useQuery } from '@apollo/client';
import { QUERY_GET_LOCAL_ITEMS } from '@fluentsolar/graphql';
import {
  getLocalStorageInfo,
  LocalItemsProps,
  localItemsVar,
  setLocalStorageInfo,
} from '../src/utils';
import * as React from 'react';

export function useApolloState(): {
  data: LocalItemsProps;
  setApolloState: (data: LocalItemsProps) => void;
  setLocalStorageState: (data: LocalItemsProps) => void;
  setAllState: (data: LocalItemsProps) => void;
  // fetch: () => void;
  clear: () => void;
} {
  // const router = useRouter();
  const localInfo = useQuery(QUERY_GET_LOCAL_ITEMS);

  React.useEffect(() => {
    // fetch();
  }, []);


  const setApolloState = (data: LocalItemsProps) => {
    const localItems = localItemsVar();
    localItemsVar({ ...localItems, ...data });
  };

  const setLocalStorageState = (data: LocalItemsProps) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (process.browser) {
      const localStorageInfo = getLocalStorageInfo();
      setLocalStorageInfo({ ...localStorageInfo, ...data });
    }
  };

  const setAllState = (data: LocalItemsProps) => {
    if (data.role === 'ADMIN' || data.role === 'SUPER_ADMIN') {
      // data.salesRepInfo = {
      //   id: 4,
      // };
    }

    setApolloState(data);
    setLocalStorageState(data);
  };

  const clear = () => {
    // Clear apolloState and LocalStorage
  };

  let data;
  if (localInfo?.data?.localItems?.token) {
    data = localInfo?.data?.localItems;
  } else {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (process.browser) {
      data = getLocalStorageInfo();
    }
  }
  // console.log('apolloState', data);
  return {
    data,
    setApolloState,
    setLocalStorageState,
    setAllState,
    clear,
  };
}
