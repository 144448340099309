import Image from 'next/image';
import * as React from 'react';

export const FluentLogo: React.FC<{ style?: React.CSSProperties }> = (props: {
  style?: React.CSSProperties;
}) => {
  return (
    <div style={{ width: '100%', height: 'auto', maxWidth: 200 }}>
      <img src="/img/FluentSolarLogo_white.png" alt="Fluent Solar Logo" />
    </div>
  );
};
